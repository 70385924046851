export enum CashBookErrorType {
	UNKNOWN = 'CASH_BOOK_ERROR_TYPE/UNKNOWN',
	FAILED_TO_LOAD_BACKUP = 'CASH_BOOK_ERROR_TYPE/FAILED_TO_LOAD_BACKUP',
	INVALID_BACKUP = 'CASH_BOOK_ERROR_TYPE/INVALID_BACKUP',
	FAILED_TO_SAVE_BACKUP_TO_LOCAL_STORAGE = 'CASH_BOOK_ERROR_TYPE/FAILED_TO_SAVE_BACKUP_TO_LOCAL_STORAGE',
	FAILED_TO_RESET = 'CASH_BOOK_ERROR_TYPE/FAILED_TO_RESET',
	FAILED_TO_UPLOAD_BACKUP = 'CASH_BOOK_ERROR_TYPE/FAILED_TO_UPLOAD_BACKUP',
	FAILED_TO_WRITE_TO_FILE = 'CASH_BOOK_ERROR_TYPE/FAILED_TO_WRITE_TO_FILE',
	FAILED_TO_EXPORT = 'CASH_BOOK_ERROR_TYPE/FAILED_TO_EXPORT',
	FAILED_TO_EXPORT_BACKUP = 'CASH_BOOK_ERROR_TYPE/FAILED_TO_LOAD_BACKUP',
}

export class CashBookError {
	constructor(public type: CashBookErrorType, public error: Error) {}
}
