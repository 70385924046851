import React from 'react';
import { WithClasses } from '../../models/props';

export const Cashier: React.FC<WithClasses> = (props) => (
	<svg {...props} viewBox="0 0 700 700">
		<defs>
			<symbol id="q" overflow="visible">
				<path d="m29.312-1.75c-1.5117 0.78125-3.0898 1.3711-4.7344 1.7656-1.6367 0.40625-3.3398 0.60938-5.1094 0.60938-5.3125 0-9.5273-1.4844-12.641-4.4531-3.1055-2.9688-4.6562-7-4.6562-12.094s1.5508-9.125 4.6562-12.094c3.1133-2.9688 7.3281-4.4531 12.641-4.4531 1.7695 0 3.4727 0.19922 5.1094 0.59375 1.6445 0.39844 3.2227 0.99219 4.7344 1.7812v6.5938c-1.5312-1.0391-3.0391-1.8008-4.5156-2.2812-1.4805-0.48828-3.0391-0.73438-4.6719-0.73438-2.9375 0-5.2461 0.94531-6.9219 2.8281-1.6797 1.875-2.5156 4.4648-2.5156 7.7656 0 3.293 0.83594 5.8828 2.5156 7.7656 1.6758 1.875 3.9844 2.8125 6.9219 2.8125 1.6328 0 3.1914-0.23828 4.6719-0.71875 1.4766-0.48828 2.9844-1.2539 4.5156-2.2969z" />
			</symbol>
			<symbol id="b" overflow="visible">
				<path d="m21.453-17.406c-0.67969-0.3125-1.3516-0.53906-2.0156-0.6875-0.65625-0.15625-1.3203-0.23438-1.9844-0.23438-1.9688 0-3.4844 0.63281-4.5469 1.8906-1.0547 1.2617-1.5781 3.0703-1.5781 5.4219v11.016h-7.6562v-23.922h7.6562v3.9219c0.97656-1.5625 2.1016-2.7031 3.375-3.4219 1.2812-0.71875 2.8125-1.0781 4.5938-1.0781 0.25 0 0.52344 0.011719 0.82812 0.03125 0.30078 0.023438 0.73438 0.070312 1.2969 0.14062z" />
			</symbol>
			<symbol id="d" overflow="visible">
				<path d="m27.562-12.031v2.1875h-17.891c0.1875 1.793 0.83203 3.1367 1.9375 4.0312 1.1133 0.89844 2.6719 1.3438 4.6719 1.3438 1.6016 0 3.25-0.23438 4.9375-0.70312 1.6875-0.47656 3.4219-1.2031 5.2031-2.1719v5.8906c-1.8047 0.6875-3.6094 1.2031-5.4219 1.5469-1.8125 0.35156-3.6211 0.53125-5.4219 0.53125-4.3359 0-7.7031-1.0977-10.109-3.2969-2.3984-2.207-3.5938-5.2969-3.5938-9.2656 0-3.9062 1.1758-6.9727 3.5312-9.2031 2.3633-2.2383 5.6094-3.3594 9.7344-3.3594 3.7578 0 6.7695 1.1367 9.0312 3.4062 2.2578 2.2617 3.3906 5.2812 3.3906 9.0625zm-7.8594-2.5312c0-1.457-0.42969-2.6289-1.2812-3.5156-0.84375-0.89453-1.9492-1.3438-3.3125-1.3438-1.4922 0-2.6992 0.41797-3.625 1.25-0.91797 0.83594-1.4922 2.0391-1.7188 3.6094z" />
			</symbol>
			<symbol id="a" overflow="visible">
				<path d="m14.391-10.766c-1.5938 0-2.793 0.27344-3.5938 0.8125-0.80469 0.54297-1.2031 1.3398-1.2031 2.3906 0 0.96875 0.32031 1.7305 0.96875 2.2812 0.64453 0.54297 1.5469 0.8125 2.7031 0.8125 1.4375 0 2.6445-0.51562 3.625-1.5469 0.98828-1.0312 1.4844-2.3203 1.4844-3.875v-0.875zm11.688-2.8906v13.656h-7.7031v-3.5469c-1.0312 1.4492-2.1875 2.5078-3.4688 3.1719s-2.8398 1-4.6719 1c-2.4805 0-4.4961-0.72266-6.0469-2.1719-1.543-1.4453-2.3125-3.3203-2.3125-5.625 0-2.8125 0.96094-4.8672 2.8906-6.1719 1.9375-1.3125 4.9688-1.9688 9.0938-1.9688h4.5156v-0.60938c0-1.207-0.48047-2.0938-1.4375-2.6562-0.94922-0.5625-2.4375-0.84375-4.4688-0.84375-1.6367 0-3.1562 0.16797-4.5625 0.5-1.4062 0.32422-2.7188 0.8125-3.9375 1.4688v-5.8281c1.6445-0.40625 3.2891-0.70703 4.9375-0.90625 1.6562-0.20703 3.3047-0.3125 4.9531-0.3125 4.3203 0 7.4375 0.85547 9.3438 2.5625 1.9141 1.6992 2.875 4.4609 2.875 8.2812z" />
			</symbol>
			<symbol id="h" overflow="visible">
				<path d="m12.031-30.719v6.7969h7.875v5.4688h-7.875v10.141c0 1.1172 0.21875 1.8711 0.65625 2.2656 0.4375 0.38672 1.3125 0.57812 2.625 0.57812h3.9375v5.4688h-6.5625c-3.0234 0-5.1641-0.62891-6.4219-1.8906-1.2617-1.2578-1.8906-3.3984-1.8906-6.4219v-10.141h-3.7969v-5.4688h3.7969v-6.7969z" />
			</symbol>
			<symbol id="c" overflow="visible">
				<path d="m19.953-20.422v-12.812h7.6875v33.234h-7.6875v-3.4531c-1.0547 1.4062-2.2148 2.4375-3.4844 3.0938-1.2734 0.65625-2.7422 0.98438-4.4062 0.98438-2.9492 0-5.3711-1.1719-7.2656-3.5156-1.8867-2.3438-2.8281-5.3594-2.8281-9.0469s0.94141-6.7031 2.8281-9.0469c1.8945-2.3438 4.3164-3.5156 7.2656-3.5156 1.6562 0 3.1172 0.33594 4.3906 1 1.2812 0.65625 2.4453 1.6836 3.5 3.0781zm-5.0469 15.484c1.6445 0 2.8945-0.59766 3.75-1.7969 0.86328-1.1953 1.2969-2.9297 1.2969-5.2031 0-2.2812-0.43359-4.0195-1.2969-5.2188-0.85547-1.1953-2.1055-1.7969-3.75-1.7969-1.625 0-2.8711 0.60156-3.7344 1.7969-0.85547 1.1992-1.2812 2.9375-1.2812 5.2188 0 2.2734 0.42578 4.0078 1.2812 5.2031 0.86328 1.1992 2.1094 1.7969 3.7344 1.7969z" />
			</symbol>
			<symbol id="g" overflow="visible">
				<path d="m16.406-4.9375c1.6328 0 2.8828-0.59766 3.75-1.7969 0.86328-1.1953 1.2969-2.9297 1.2969-5.2031 0-2.2812-0.43359-4.0195-1.2969-5.2188-0.86719-1.1953-2.1172-1.7969-3.75-1.7969-1.6367 0-2.8906 0.60547-3.7656 1.8125-0.875 1.1992-1.3125 2.9336-1.3125 5.2031 0 2.2617 0.4375 3.9961 1.3125 5.2031 0.875 1.1992 2.1289 1.7969 3.7656 1.7969zm-5.0781-15.484c1.0508-1.3945 2.2188-2.4219 3.5-3.0781 1.2812-0.66406 2.7539-1 4.4219-1 2.9453 0 5.3672 1.1719 7.2656 3.5156 1.8945 2.3438 2.8438 5.3594 2.8438 9.0469s-0.94922 6.7031-2.8438 9.0469c-1.8984 2.3438-4.3203 3.5156-7.2656 3.5156-1.668 0-3.1406-0.33594-4.4219-1s-2.4492-1.6914-3.5-3.0781v3.4531h-7.6562v-33.234h7.6562z" />
			</symbol>
			<symbol id="f" overflow="visible">
				<path d="m0.53125-23.922h7.6562l6.4219 16.234 5.4688-16.234h7.6562l-10.062 26.188c-1.0117 2.6641-2.1953 4.5234-3.5469 5.5781-1.3438 1.0625-3.1211 1.5938-5.3281 1.5938h-4.4219v-5.0156h2.3906c1.3008 0 2.2422-0.21094 2.8281-0.625 0.59375-0.40625 1.0547-1.1484 1.3906-2.2188l0.20312-0.65625z" />
			</symbol>
			<symbol id="e" overflow="visible">
				<path d="m4.0156-31.891h8.2188v28.797c0 3.9688-1.0781 6.9375-3.2344 8.9062s-5.4219 2.9531-9.7969 2.9531h-1.6562v-6.2188h1.2812c1.707 0 3-0.48047 3.875-1.4375 0.875-0.96094 1.3125-2.3594 1.3125-4.2031z" />
			</symbol>
			<symbol id="p" overflow="visible">
				<path d="m15.062-19.031c-1.6992 0-2.9961 0.60938-3.8906 1.8281-0.88672 1.2188-1.3281 2.9766-1.3281 5.2656 0 2.293 0.44141 4.0469 1.3281 5.2656 0.89453 1.2188 2.1914 1.8281 3.8906 1.8281 1.6641 0 2.9375-0.60938 3.8125-1.8281 0.88281-1.2188 1.3281-2.9727 1.3281-5.2656 0-2.2891-0.44531-4.0469-1.3281-5.2656-0.875-1.2188-2.1484-1.8281-3.8125-1.8281zm0-5.4688c4.1133 0 7.3281 1.1094 9.6406 3.3281s3.4688 5.2969 3.4688 9.2344c0 3.9297-1.1562 7.0078-3.4688 9.2344-2.3125 2.2188-5.5273 3.3281-9.6406 3.3281-4.1367 0-7.3711-1.1094-9.7031-3.3281-2.3242-2.2266-3.4844-5.3047-3.4844-9.2344 0-3.9375 1.1602-7.0156 3.4844-9.2344 2.332-2.2188 5.5664-3.3281 9.7031-3.3281z" />
			</symbol>
			<symbol id="o" overflow="visible">
				<path d="m27.734-14.562v14.562h-7.7031v-11.156c0-2.0625-0.046875-3.4844-0.14062-4.2656-0.085937-0.78125-0.24219-1.3594-0.46875-1.7344-0.30469-0.5-0.71094-0.88281-1.2188-1.1562-0.51172-0.28125-1.0938-0.42188-1.75-0.42188-1.5938 0-2.8516 0.61719-3.7656 1.8438-0.90625 1.2305-1.3594 2.9375-1.3594 5.125v11.766h-7.6562v-23.922h7.6562v3.5c1.1445-1.3945 2.3633-2.4219 3.6562-3.0781 1.3008-0.66406 2.7383-1 4.3125-1 2.7578 0 4.8516 0.85156 6.2812 2.5469 1.4375 1.6875 2.1562 4.1523 2.1562 7.3906z" />
			</symbol>
			<symbol id="n" overflow="visible">
				<path d="m23.375-5.8125h-12.859l-2.0312 5.8125h-8.2656l11.812-31.891h9.7969l11.812 31.891h-8.2656zm-10.812-5.9219h8.7344l-4.3594-12.688z" />
			</symbol>
			<symbol id="m" overflow="visible">
				<path d="m3.6719-33.234h7.6562v33.234h-7.6562z" />
			</symbol>
			<symbol id="l" overflow="visible">
				<path d="m19.422-33.234v5.0156h-4.2344c-1.0859 0-1.8398 0.19922-2.2656 0.59375-0.42969 0.38672-0.64062 1.0625-0.64062 2.0312v1.6719h6.5312v5.4688h-6.5312v18.453h-7.6406v-18.453h-3.8125v-5.4688h3.8125v-1.6719c0-2.6016 0.72266-4.5234 2.1719-5.7656 1.457-1.25 3.707-1.875 6.75-1.875z" />
			</symbol>
			<symbol id="k" overflow="visible">
				<path d="m3.6719-23.922h7.6562v23.922h-7.6562zm0-9.3125h7.6562v6.2344h-7.6562z" />
			</symbol>
			<symbol id="j" overflow="visible">
				<path d="m22.359-23.172v5.7969c-1.6367-0.67578-3.2148-1.1875-4.7344-1.5312-1.5234-0.34375-2.9609-0.51562-4.3125-0.51562-1.4609 0-2.543 0.18359-3.25 0.54688-0.69922 0.36719-1.0469 0.92188-1.0469 1.6719 0 0.61719 0.26562 1.0898 0.79688 1.4219 0.53125 0.32422 1.4883 0.5625 2.875 0.71875l1.3438 0.1875c3.9141 0.5 6.5508 1.3242 7.9062 2.4688 1.3516 1.1367 2.0312 2.9219 2.0312 5.3594 0 2.5547-0.94531 4.4688-2.8281 5.75-1.875 1.2812-4.6797 1.9219-8.4062 1.9219-1.5859 0-3.2188-0.125-4.9062-0.375s-3.4219-0.625-5.2031-1.125v-5.8125c1.5195 0.74219 3.082 1.2969 4.6875 1.6719 1.6016 0.36719 3.2344 0.54688 4.8906 0.54688 1.4883 0 2.6094-0.20312 3.3594-0.60938 0.75781-0.41406 1.1406-1.0312 1.1406-1.8438 0-0.6875-0.26172-1.1953-0.78125-1.5312-0.52344-0.33203-1.5586-0.59375-3.1094-0.78125l-1.3438-0.17188c-3.4062-0.42578-5.793-1.2109-7.1562-2.3594-1.3672-1.1562-2.0469-2.9102-2.0469-5.2656 0-2.5312 0.86719-4.4062 2.6094-5.625 1.7383-1.2266 4.3984-1.8438 7.9844-1.8438 1.4062 0 2.8828 0.10938 4.4375 0.32812 1.5508 0.21094 3.2383 0.54297 5.0625 1z" />
			</symbol>
			<symbol id="i" overflow="visible">
				<path d="m27.734-14.562v14.562h-7.7031v-11.109c0-2.0938-0.046875-3.5312-0.14062-4.3125-0.085937-0.78125-0.24219-1.3594-0.46875-1.7344-0.30469-0.5-0.71094-0.88281-1.2188-1.1562-0.51172-0.28125-1.0938-0.42188-1.75-0.42188-1.5938 0-2.8516 0.61719-3.7656 1.8438-0.90625 1.2305-1.3594 2.9375-1.3594 5.125v11.766h-7.6562v-33.234h7.6562v12.812c1.1445-1.3945 2.3633-2.4219 3.6562-3.0781 1.3008-0.66406 2.7383-1 4.3125-1 2.7578 0 4.8516 0.85156 6.2812 2.5469 1.4375 1.6875 2.1562 4.1523 2.1562 7.3906z" />
			</symbol>
		</defs>
		<g>
			<path d="m575.84 353.06-15.836-126.09c-0.046875-0.37891-0.046875-0.76172 0-1.1406v-85.836c0.042969-9.4727-3.0312-18.699-8.75-26.25 5.6797-7.5742 8.75-16.785 8.75-26.25v-17.5c0-11.602-4.6094-22.73-12.812-30.938-8.207-8.2031-19.336-12.812-30.938-12.812h-140c-11.602 0-22.73 4.6094-30.938 12.812-8.2031 8.207-12.812 19.336-12.812 30.938v17.5c0.023438 2.9375 0.31641 5.8672 0.875 8.75h-149.62c-11.602 0-22.73 4.6094-30.938 12.812-8.2031 8.207-12.812 19.336-12.812 30.938v85.926c0.046875 0.375 0.046875 0.75781 0 1.1367l-15.836 126c-8.207 3.1875-15.262 8.7734-20.242 16.035-4.9766 7.2578-7.6523 15.848-7.6719 24.652v87.5c0 11.602 4.6094 22.73 12.812 30.938 8.207 8.2031 19.336 12.812 30.938 12.812h420c11.602 0 22.73-4.6094 30.938-12.812 8.2031-8.207 12.812-19.336 12.812-30.938v-87.5c-0.019531-8.8047-2.6953-17.395-7.6719-24.652-4.9805-7.2617-12.035-12.848-20.242-16.035zm-208.34-283.06c0-4.832 3.918-8.75 8.75-8.75h140c2.3203 0 4.5469 0.92188 6.1875 2.5625s2.5625 3.8672 2.5625 6.1875v17.5c0 2.3203-0.92188 4.5469-2.5625 6.1875s-3.8672 2.5625-6.1875 2.5625h-140c-4.832 0-8.75-3.918-8.75-8.75zm-192.5 161.26c0.12891-1.8047 0.12891-3.6172 0-5.4258v-85.836c0-4.832 3.918-8.75 8.75-8.75h332.5c2.3203 0 4.5469 0.92188 6.1875 2.5625s2.5625 3.8672 2.5625 6.1875v85.926c-0.12891 1.8047-0.12891 3.6172 0 5.4258l15.137 118.65h-380.27zm393.75 249.99c0 2.3203-0.92188 4.5469-2.5625 6.1875s-3.8672 2.5625-6.1875 2.5625h-420c-4.832 0-8.75-3.918-8.75-8.75v-87.5c0-4.832 3.918-8.75 8.75-8.75h420c2.3203 0 4.5469 0.92188 6.1875 2.5625s2.5625 3.8672 2.5625 6.1875z" />
			<path d="m367.5 420h-35c-6.2539 0-12.031 3.3359-15.156 8.75s-3.125 12.086 0 17.5 8.9023 8.75 15.156 8.75h35c6.2539 0 12.031-3.3359 15.156-8.75s3.125-12.086 0-17.5-8.9023-8.75-15.156-8.75z" />
			<path d="m350 166.25h-122.5c-6.2539 0-12.031 3.3359-15.156 8.75s-3.125 12.086 0 17.5 8.9023 8.75 15.156 8.75h122.5c6.2539 0 12.031-3.3359 15.156-8.75s3.125-12.086 0-17.5-8.9023-8.75-15.156-8.75z" />
			<path d="m367.5 253.75c0 9.6641-7.8359 17.5-17.5 17.5s-17.5-7.8359-17.5-17.5 7.8359-17.5 17.5-17.5 17.5 7.8359 17.5 17.5" />
			<path d="m428.75 253.75c0 9.6641-7.8359 17.5-17.5 17.5s-17.5-7.8359-17.5-17.5 7.8359-17.5 17.5-17.5 17.5 7.8359 17.5 17.5" />
			<path d="m490 253.75c0 9.6641-7.8359 17.5-17.5 17.5s-17.5-7.8359-17.5-17.5 7.8359-17.5 17.5-17.5 17.5 7.8359 17.5 17.5" />
			<path d="m367.5 306.25c0 9.6641-7.8359 17.5-17.5 17.5s-17.5-7.8359-17.5-17.5 7.8359-17.5 17.5-17.5 17.5 7.8359 17.5 17.5" />
			<path d="m428.75 306.25c0 9.6641-7.8359 17.5-17.5 17.5s-17.5-7.8359-17.5-17.5 7.8359-17.5 17.5-17.5 17.5 7.8359 17.5 17.5" />
			<path d="m490 306.25c0 9.6641-7.8359 17.5-17.5 17.5s-17.5-7.8359-17.5-17.5 7.8359-17.5 17.5-17.5 17.5 7.8359 17.5 17.5" />
			<use x="70" y="691.25" xlinkHref="#q" />
			<use x="102.109375" y="691.25" xlinkHref="#b" />
			<use x="123.683594" y="691.25" xlinkHref="#d" />
			<use x="153.355469" y="691.25" xlinkHref="#a" />
			<use x="182.878906" y="691.25" xlinkHref="#h" />
			<use x="203.792969" y="691.25" xlinkHref="#d" />
			<use x="233.464844" y="691.25" xlinkHref="#c" />
			<use x="280.011719" y="691.25" xlinkHref="#g" />
			<use x="311.328125" y="691.25" xlinkHref="#f" />
			<use x="355.078125" y="691.25" xlinkHref="#e" />
			<use x="371.359375" y="691.25" xlinkHref="#p" />
			<use x="401.414062" y="691.25" xlinkHref="#b" />
			<use x="422.992188" y="691.25" xlinkHref="#c" />
			<use x="454.308594" y="691.25" xlinkHref="#a" />
			<use x="483.832031" y="691.25" xlinkHref="#o" />
			<use x="530.207031" y="691.25" xlinkHref="#n" />
			<use x="564.066406" y="691.25" xlinkHref="#m" />
			<use x="579.0625" y="691.25" xlinkHref="#l" />
			<use x="598.097656" y="691.25" xlinkHref="#a" />
			<use x="627.621094" y="691.25" xlinkHref="#b" />
			<use x="649.195312" y="691.25" xlinkHref="#k" />
			<use x="664.191406" y="691.25" xlinkHref="#j" />
			<use x="690.234375" y="691.25" xlinkHref="#i" />
		</g>
	</svg>
);
