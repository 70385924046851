import React from 'react';
import { WithClasses } from '../../models/props';

export const CashBook: React.FC<WithClasses> = (props) => (
	<svg {...props} viewBox="175 103 350 358" fill="currentColor">
		<title>cash book icon</title>
		<path d="m389.76 291.76c21.281 7.8398 21.84 11.762 22.961 20.719 0 1.6797-1.1211 7.8398-5.0391 12.879-3.3594 3.9219-7.8398 5.6016-13.441 5.6016-12.879 0-15.121-3.3594-20.719-12.879l-1.125-2.2422c-3.9219-6.1602-11.762-8.3984-17.922-4.4805-6.1602 3.9219-8.3984 11.762-4.4805 17.922l0.55859 1.1211c5.6016 8.9609 12.32 20.719 30.238 24.641v10.641c0 7.2812 6.1602 13.441 13.441 13.441s13.441-6.1602 13.441-13.441v-11.199c7.8398-2.2383 14.559-6.1602 20.16-12.32 9.5195-10.641 12.879-24.078 11.762-33.039-2.2383-19.602-8.9609-31.359-40.32-42.559-24.641-8.9609-24.641-15.121-24.641-19.039 0-16.238 11.199-18.48 20.16-18.48 14.559 0 18.48 10.078 19.039 11.762 2.2383 6.7188 9.5195 10.641 16.238 8.9609 7.2812-2.2383 11.199-9.5195 8.9609-16.238-2.8008-8.9609-12.32-24.078-31.359-29.121v-11.199c0-7.2812-6.1602-13.441-13.441-13.441s-13.441 6.1602-13.441 13.441v11.199c-24.078 5.6016-33.039 25.762-33.039 43.121 0.007813 25.75 21.285 36.391 42.008 44.23z" />
		<path d="m502.32 110.88h-257.6c-7.2812 0-13.441 6.1602-13.441 13.441v49.84h-33.602c-7.2812 0-13.441 6.1602-13.441 13.441s6.1602 13.441 13.441 13.441h33.602v66.078h-33.602c-7.2812 0-13.441 6.1602-13.441 13.441s6.1602 13.441 13.441 13.441h33.602v66.078h-33.602c-7.2812 0-13.441 6.1602-13.441 13.441s6.1602 13.441 13.441 13.441h33.602v49.84c0 7.2812 6.1602 13.441 13.441 13.441h257.04c7.2812 0 13.441-6.1602 13.441-13.441v-312.48c0-7.2812-5.5977-13.441-12.879-13.441zm-13.441 311.36h-230.72v-36.961h22.961c7.2812 0 13.441-6.1602 13.441-13.441s-6.1602-13.441-13.441-13.441h-22.961v-64.953h22.961c7.2812 0 13.441-6.1602 13.441-13.441s-6.1602-13.441-13.441-13.441h-22.961v-65.52h22.961c7.2812 0 13.441-6.1602 13.441-13.441s-6.1602-13.441-13.441-13.441h-22.961v-36.961h230.72z" />
	</svg>
);
